const BeforeImages = () => {
  return (
    <>
      <section className="pt-8 mx-8 bg-white">
        <div className="flex justify-center mb-6 text-2xl text-center text-gray-700 font-PermanentMarket">
          Friday 19 January 2024, Collingwood
        </div>
      </section>
    </>
  );
};

export default BeforeImages;
