import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="w-full bg-white border-t-2">
        <div className="py-0 mx-6 text-sm text-gray-700 font-Lato">
          <div className="mx-0 md:justify-between md:flex md:mx-16">
            <div className="order-last my-4">
              <p className="text-lg">Pippa Palooza - The Farewell Tour</p>
              <p className="">
                Venue
                <span className="ml-1 text-button hover:text-primary">
                  <a
                    href="https://rupertonrupert.com.au"
                    target="_blank "
                    rel="noreferrer"
                  >
                    Rupert on Rupert
                  </a>
                </span>
              </p>
              <p className="">
                Photography by
                <span className="ml-1 text-button hover:text-primary">
                  <a
                    href="https://packinglightphotography.com.au/"
                    target="_blank "
                    rel="noreferrer"
                  >
                    Packing Light
                  </a>
                </span>
              </p>
              <p className="">
                Comedy by
                <span className="ml-1 text-button hover:text-primary">
                  <a
                    href="https://www.spizzica.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Cristina Spizzica
                  </a>
                </span>
              </p>
              <p className="">
                Comedy cabaret by
                <span className="ml-1 text-button hover:text-primary">
                  <a
                    href="https://dollydiamond.com.au/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Dolly Diamond
                  </a>
                </span>
              </p>
              <p className="">
                Live music by
                <span className="ml-1 text-button hover:text-primary">
                  <a
                    href="https://www.instagram.com/drunkmums/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Drunk Mums
                  </a>
                </span>
              </p>
              <p className="">
                Makeup by
                <span className="ml-1 text-button hover:text-primary">
                  <a
                    href="https://www.instagram.com/prettygamemua"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Pretty Game MUA
                  </a>
                </span>
              </p>
            </div>
            <div className="my-4 md:my-16">
              <p>
                Copyright <span>&copy;</span> 2024 Pippa Burgess
              </p>
              <p className="">
                Website by
                <span className="ml-1 text-button hover:text-primary">
                  <a
                    href="https://webwings.com.au/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Webwings Internet Marketing
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
