import logo from "../images/pippaLogo.png";

const HeaderSection = () => {
  return (
    <header className="flex justify-center py-4 bg-white border-b-2">
      <img src={logo} alt="Pippa Palooza" className="mx-6" />
    </header>
  );
};

export default HeaderSection;
