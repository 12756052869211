import Spinner from "./Spinner";

const Loading = () => {
  return (
    <div className="flex justify-center mt-12">
      <Spinner />
    </div>
  );
};

export default Loading;
