import { lazy, Suspense } from "react";
import BeforeImages from "./components/BeforeImages";
import Footer from "./components/Footer";
import HeaderSection from "./components/HeaderSection";
import Loading from "./components/Loading";
const LazySlideshow = lazy(() => import("./components/Slideshow"));

function App() {
  return (
    <>
      <div>
        <div className="flex flex-col justify-between min-h-screen">
          <HeaderSection />
          <main className="flex-grow">
            <BeforeImages />
            <Suspense fallback={<Loading />}>
              <LazySlideshow />
            </Suspense>
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default App;
